import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { FirebaseServiceProvider } from '../providers/firebase-service';

@Injectable({
  providedIn: 'root'
})
export class CrudService {

  public firestore: AngularFirestore = this.fbService.dbCliente;

  constructor(private fbService: FirebaseServiceProvider) {
  }
  getCollection(entidade: string): AngularFirestoreCollection<any> {
    return this.firestore.collection(entidade);
  }

  listar(entidade: string, campo: string, value: boolean): Observable<any> {
    return this.firestore.collection<any>(entidade, ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;

      if (campo && value) { query = query.where(campo, '==', value) };

      return query;
    }).valueChanges();
  }
  
  listarByFiltro(entidade: string, filtro: any[]): Observable<any> {
    return this.firestore.collection<any>(entidade, ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;

      if(filtro){
        for(let f of filtro){
          if (f.campo && f.operacao) { query = query.where(f.campo, f.operacao, f.value) };
        }
      }
    
      return query;
    }).valueChanges();
  }

  listarByUsuarioLogado(entidade: string, usuarioLogado: any): Observable<any> {

    if(usuarioLogado && usuarioLogado.gestor){
      return this.firestore.collection<any>(entidade, ref => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
  
        if (usuarioLogado) { query = query.where('gestor', '==', usuarioLogado.email) };
  
        return query;
      }).valueChanges();
    }else{
      return this.listar(entidade, null, null);
    }
   
  }

  findByGeneric(entidade: string, campo: string, value: any): Observable<any> {
    return this.firestore.collection<any>(entidade, ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;

      if (value) { query = query.where(campo, '==', value) };

      return query;
    }).valueChanges(); 
  }

  salvar(entidade: string, objeto: any) {
    let id1: string = Math.random().toString(36).slice(2);
    let id2: string = Math.random().toString(36).slice(2);
    let id = id1+"-"+id2;
    objeto.id = id;
    this.getCollection(entidade).doc<any>(id).set(Object.assign({}, objeto));
  }

  atualizar(entidade: string, objeto: any) {
    let id: string = objeto.id;
    this.getCollection(entidade).doc<any>(id).set(Object.assign({}, objeto));
  }

  excluir(entidade: string, id: string) {
    this.getCollection(entidade).doc<any>(id).delete();
  }
}
