import { Directive, Input , ElementRef, Renderer2, OnInit} from '@angular/core';

@Directive({
  selector: '[appProduto]'
})
export class Produtoirective implements OnInit{
    
  @Input() id: string;
  @Input() produtos: any[];

  constructor(private elRef: ElementRef, private render: Renderer2){

  }

  ngOnInit(){

    for(var i = 0; i < this.produtos.length; i++){

      if(this.produtos[i].id == this.id){

        const text = this.render.createText(this.produtos[i].nome);
        this.render.appendChild(this.elRef.nativeElement, text);
      }
    }

  }
    

}

