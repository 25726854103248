import {Component, ViewChild, OnInit, TemplateRef, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { ModalService } from '../../../services/modal.service';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { Categoria } from '../../../model/categoria';
import { NgForm } from '@angular/forms';
import { CrudService } from "../../../services/crudService";
import { ToastrService } from 'ngx-toastr';
 
@Component({
  selector: 'app-detalhes-categorias',
  templateUrl: 'categorias-detalhes.component.html'
})  
export class CategoriasDetalhesComponent implements OnInit{
      
  @ViewChild('modalcategoria', {static: false}) public modalcategoria: ModalDirective;

  sub: boolean = false;
  _categoria: Categoria = new Categoria();
  @Output() loadcategorias = new EventEmitter();
  titulo: string = "Nova Categoria";
  movSubSalvar: Subscription;
  movSubAlterar: Subscription;
  movSubIcon: Subscription;
  icones: any[];

  constructor(
    private modalService: ModalService,
    private toastr: ToastrService,
    private cadastroService: CrudService) {
  }
  
  ngOnInit() {

    if(this.sub == false){

      this.sub = true;

      this.modalService.modalCategorias.subscribe(
        (p: Categoria) => {
         
          if(p == null){
            this.titulo = "Novo Categoria"
            this._categoria = new Categoria();
          }else{
            this.titulo = "Editar Categoria"
            this._categoria = p;
          }

          this.limpaModal();

          this.getIcones();
          
          this.modalcategoria.show();

        }, error => {
          console.log(error.message);
        } 
      );
    } 
 
  }

  async getIcones(){
 
    this.icones = [];

    if (this.movSubIcon && this.movSubIcon.closed == false){
      this.movSubIcon.unsubscribe();
    }

    if (!this.movSubIcon || this.movSubIcon.closed){

      this.movSubIcon = this.cadastroService.listar("icones", null, null).subscribe(
        (result: any) =>{

          this.icones =  result;
  
       },error=>{
            console.log(error);
       });

    }
  
  }

  hideModal(){
    this.modalcategoria.hide();
    this.loadcategorias.emit();
  }
 
  limpaModal(){
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  onSubmit(form: NgForm) {

    if (!form.valid) {
      return;
    }

    if(this._categoria.id){
      this.atualizar();
    }else{
      this.salvar();
    }
      
    form.reset();
  }

  salvar(){
 
    let objcategoria = JSON.parse(JSON.stringify(this._categoria));

    if (this.movSubSalvar && this.movSubSalvar.closed == false){
      this.movSubSalvar.unsubscribe();
    }

    if (!this.movSubSalvar || this.movSubSalvar.closed){
     
      this.movSubSalvar = this.cadastroService.findByGeneric("categorias", "codigo", this._categoria.codigo).subscribe(
        (result: any) =>{

          if(result.length > 0){
            this.toastr.error('Este código já esta sendo utilizado por outra categoria!', 'Atenção!');
            this.hideModal();
          }else{
            this.cadastroService.salvar("categorias", objcategoria);

            this.toastr.success('Categoria cadastrada com sucesso!', 'Sucesso!');
        
            this.hideModal();

            this.movSubSalvar.unsubscribe();
          }
  
       },error=>{
            console.log(error);
            this.hideModal();
       });

    }
   
  } 

  atualizar(){

    let objcategoria = JSON.parse(JSON.stringify(this._categoria));

    if (this.movSubAlterar && this.movSubAlterar.closed == false){
      this.movSubSalvar.unsubscribe();
    }

    if (!this.movSubAlterar || this.movSubAlterar.closed){
     
      this.movSubAlterar = this.cadastroService.findByGeneric("categorias", "codigo", this._categoria.codigo).subscribe(
        (result: any) =>{

          if(result.length > 0){
            if(result[0].codigo == objcategoria.codigo){
              this.cadastroService.atualizar("categorias", objcategoria);

              this.toastr.success('Categoria atualizada com sucesso!', 'Sucesso!');
         
              this.hideModal();
            
              this.movSubAlterar.unsubscribe();
            }
          }
  
       },error=>{
            console.log(error);
            this.hideModal();
       });

    }

  }
}
