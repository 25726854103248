import { Directive, Input , ElementRef, Renderer2, OnInit} from '@angular/core';

@Directive({
  selector: '[appIdentificacao]'
})
export class IdentificaoDirective implements OnInit{
    
  @Input() email: string;
  @Input() usuarios: any[];
  @Input() opcao: string;

  constructor(private elRef: ElementRef, private render: Renderer2){

  }

  ngOnInit(){

    var achou = false;
    for(var i = 0; i < this.usuarios.length; i++){

      if(this.usuarios[i].EMAIL == this.email){

        achou = true;
        if(this.opcao == "NOME"){
           const text = this.render.createText(this.usuarios[i].NOME);
           this.render.appendChild(this.elRef.nativeElement, text);
        }

        if(this.opcao == "ENDERECO"){
           const text = this.render.createText(this.usuarios[i].ENDERECO);
           this.render.appendChild(this.elRef.nativeElement, text);
        }

        if(this.opcao == "TELEFONE"){
          const text = this.render.createText(this.usuarios[i].TELEFONE);
           this.render.appendChild(this.elRef.nativeElement, text);
        }
        
        
      }
      
    }

    if(achou == false){
      if(this.opcao == "NOME"){
        const text = this.render.createText(this.email);
        this.render.appendChild(this.elRef.nativeElement, text);
      }

      if(this.opcao == "ENDERECO"){
        const text = this.render.createText(" - ");
        this.render.appendChild(this.elRef.nativeElement, text);
      }
    }

  }
    

}

