import {Component, ViewChild, OnInit, TemplateRef, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { ModalService } from '../../../services/modal.service';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { Adicional } from '../../../model/Adicional';
import { NgForm } from '@angular/forms';
import { CrudService } from "../../../services/crudService";
import { ToastrService } from 'ngx-toastr';
import { AngularFireStorage } from 'angularfire2/storage';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-adicionais',
  templateUrl: 'adicionais.component.html'
})  
export class AdicionaisComponent implements OnInit{
      
  @ViewChild('modalAdicional', {static: false}) public modalAdicional: ModalDirective;

  sub: boolean = false;
  _adicional: Adicional = new Adicional();
  @Output() loadAdicionais = new EventEmitter();
  titulo: string = "Adicionais";
  movSubSalvar: Subscription;
  produtos: any[];
  adicionais: any[];
  produto: any;
  Subproduto: Subscription;

  constructor(
    private modalService: ModalService,
    private toastr: ToastrService,
    private cadastroService: CrudService,
    private confirmationDialogService: ConfirmationDialogService) {
  }
  
  ngOnInit() {

    if(this.sub == false){

      this.sub = true;

      this.modalService.modalAdicionais.subscribe(
        (obj: any) => {
         
          this.produto = obj.produto;
          this.produtos = obj.produtos;
         
          this.getAdicionais(true);

          this.limpaModal();
          this.modalAdicional.show();

        }, error => {
          console.log(error.message);
        } 
      );
    } 
 
  }

  async getAdicionais(show: boolean){
 
    this.adicionais = [];

    if (this.Subproduto && this.Subproduto.closed == false){
      this.Subproduto.unsubscribe();
    }

    if (!this.Subproduto || this.Subproduto.closed){
     
      this.Subproduto = this.cadastroService.listar("adicionais", "produtoPai", this.produto.id).subscribe(
        (result: any) =>{

          this.adicionais =  result;
          
       },error=>{
            console.log(error);
       });

    }
  
  }
  
  hideModal(){
    this.modalAdicional.hide();
    this.loadAdicionais.emit();
  }
 
  limpaModal(){
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  criar() {

    if(!this._adicional.produtoAdicional){
      this.toastr.warning('Selecione um adicional!', 'Atenção!');
    }else{
      this._adicional.produtoPai = this.produto.id;
      
      this.salvar(); 
    }
    

  }

  salvar(){
 
    if (this.movSubSalvar && this.movSubSalvar.closed == false){
      this.movSubSalvar.unsubscribe();
    }

    if (!this.movSubSalvar || this.movSubSalvar.closed){
     
      let filtro = [];
     
      let _col  ={
        "campo" : "produtoAdicional",
        "operacao" : "==",
        "value" : this._adicional.produtoAdicional
      }
      filtro.push(_col);
      
      let _col2  ={
        "campo" : "produtoPai",
        "operacao" : "==",
        "value" : this._adicional.produtoPai
      }
      filtro.push(_col2);
      
      this.movSubSalvar = this.cadastroService.listarByFiltro("adicionais", filtro).subscribe(
        (result: any) =>{

          if(result.length > 0){
            this.toastr.error('Este adicional já esta sendo utilizado!', 'Atenção!');
            this.hideModal();
          }else{
            this.cadastroService.salvar("adicionais", this._adicional);

            this.toastr.success('Adicional cadastrado com sucesso!', 'Sucesso!');
        
            this.movSubSalvar.unsubscribe();
            
          }
  
       },error=>{
            console.log(error);
            this.hideModal();
       });

    }
   
  } 


  excluir(adiciconal: any){
    
    this.confirmationDialogService.confirm('Confirmar?', 'Realmente deseja excluir ?')
      .then((confirmed) =>{
          
        if(confirmed){
          this.cadastroService.excluir("adicionais", adiciconal.id);
            
          this.toastr.success('Adicional excluído com sucesso!', 'Sucesso!');
          this.getAdicionais(false);               
           
        }
        
      })
      .catch(() => {
        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
    
  }

}
