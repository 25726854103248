// Angular
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

// Dropdowns Component
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

// Components Routing
import { ComponentsRoutingModule } from './components-routing.module';
import { TableModule } from 'primeng/table';
import { NgxSpinnerModule } from 'ngx-spinner';

import { ModalModule } from 'ngx-bootstrap/modal';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService } from '../auth/auth-interceptor.service';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { CategoriasComponent } from './categorias/categorias.component';
import { CategoriasDetalhesComponent } from './categorias/categorias-detalhes/categorias-detalhes.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import DirectivesModule from '../directives/directives.module';
import { ProdutosComponent } from './produtos/produtos.component';
import { ProdutosDetalhesComponent } from './produtos/produtos-detalhes/produtos-detalhes.component';
import { AdicionaisComponent } from './produtos/adicionais/adicionais.component';

@NgModule({
  imports: [ 
    CommonModule,
    FormsModule,
    ComponentsRoutingModule,
    BsDropdownModule.forRoot(),
    TableModule,
    NgxSpinnerModule,
    ModalModule.forRoot(),
    AngularFireAuthModule,
    DirectivesModule
  ],
  declarations: [
    CategoriasComponent,
    CategoriasDetalhesComponent,
    ConfirmationDialogComponent,
    ProdutosComponent,
    ProdutosDetalhesComponent,
    AdicionaisComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    }
  ],
})
export class ComponentsModule { }
