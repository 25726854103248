import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from './auth.service';
import { FirebaseServiceProvider } from '../providers/firebase-service';
import { ClientesService } from '../services/clientes.service';
import { Subscription } from 'rxjs';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-dashboard',
  templateUrl: '../views/login/login.component.html'
})
export class AuthComponent {

  isLoading = false;
  error: string = null;
  movSub: Subscription;
  
  constructor(
      private authService: AuthService, 
      private router: Router,
      private fbService: FirebaseServiceProvider,
      private clienteService: ClientesService
  ) {
     if(this.authService.autoLogin()){
        this.isLoading = true;
        this.fbService.init('cliente');
        this.router.navigate(['/dashboard']);
     }else{
        this.fbService.init('global');
     }
  }

  onRegistrar(){
    this.router.navigate(['/register']);
  }

  onSubmit(form: NgForm) {

    this.error = null;
    
    if (!form.valid) {
      return;
    }

    const email = form.value.email;
    const password = form.value.password;

    this.isLoading = true;
            
    const dominio = email.split("@")[1];

    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }

    if (!this.movSub || this.movSub.closed){
              
      //TENTA LOGAR PROCURANDO CONFIG PELO DOMINIO DO EMAIL, CASO CONTRARIO BUSCA PELO EMAIL
      this.movSub = this.clienteService.getCliente(dominio).subscribe(async config =>{
          
        if(config){
          this.login(dominio, config, email, password);
                            
        }else{  
          
          //NÃO ACHOU CONFIG PELO DOMINIO DO EMAIL, ENTAO BUSCA PELO EMAIL
          this.movSub = this.clienteService.getCliente(email).subscribe(async config =>{
          
            if(config){
              this.login(email, config, email, password);
                                
            }else{  
              this.error = "Domínio não permitido! Favor contatar o administrador."
              this.isLoading = false;
            }
              
          },error=>{
              console.log(error);
          });

        }
          
      },error=>{
          console.log(error);
      });

    }

  
    form.reset();
  }

  login(dominio, config, email, password){

    localStorage.setItem('userNextChefPortal', dominio);

    const secretKey = "coachitnext!!!!!";
    let configCrypto = CryptoJS.AES.encrypt(JSON.stringify(config), secretKey).toString();
    
    localStorage.setItem(dominio, configCrypto);
    
    let promise = this.fbService.init('cliente');

    promise.then(conectado => {
      if(conectado){
        this.loginCliente(email, password);
      }else{
        this.error = "Não foi possivel conectar na base de dados."
        this.isLoading = false;
      }
      
    });

  }
  
  loginCliente(email, password) {

    this.error = null;

    this.isLoading = true;

    this.authService.login(email,password)
      .then(
      (result) => {
     
          this.isLoading = false;

          if (result.user) {
          //if (result.user.emailVerified) {
            this.authService.handleAuthentication(result.user.email, result.user.uid, result.user.refreshToken, 3600);

            this.router.navigate(['/dashboard']);

          } else {
            this.error = "E-mail não verificado!"
          }

      },
      error => {
        console.log(error);
        this.error = this.authService.handleError(error.code);
        this.isLoading = false;
      }
    );
  
  }

  ngOnDestroy(){
    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }
  }

}
