import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription, Observable } from 'rxjs'; 
import { CrudService } from "../../services/crudService";
import { ModalService } from '../../services/modal.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-produtos',
  templateUrl: './produtos.component.html',
  styleUrls: ['./produtos.component.scss']
})  
export class ProdutosComponent implements OnInit {
    
  Subproduto: Subscription;
  Subcategoria: Subscription;
  produtos: any[];
  categorias: any[];
  
  constructor(
      private CrudService: CrudService,
      private modalService: ModalService,
      private cadastroService: CrudService,
      private toastr: ToastrService,
      private confirmationDialogService: ConfirmationDialogService
  ){
    
  }

  ngOnInit() {
    this.getCategorias();
  }

  async getProdutos(){
 
    this.produtos = [];

    if (this.Subproduto && this.Subproduto.closed == false){
      this.Subproduto.unsubscribe();
    }

    if (!this.Subproduto || this.Subproduto.closed){
     
      this.Subproduto = this.CrudService.listar("produtos", null, null).subscribe(
        (result: any) =>{

          this.produtos =  result;

       },error=>{
            console.log(error);
       });

    }
  
  }

  async getCategorias(){
 
    this.categorias = [];

    if (this.Subcategoria && this.Subcategoria.closed == false){
      this.Subcategoria.unsubscribe();
    }

    if (!this.Subcategoria || this.Subcategoria.closed){

      this.Subcategoria = this.CrudService.listar("categorias", null, null).subscribe(
        (result: any) =>{

          this.categorias =  result;

          this.getProdutos();
  
       },error=>{
            console.log(error);
       });

    }
  
  }

  ngOnDestroy(){
    if (this.Subproduto && this.Subproduto.closed == false){
      this.Subproduto.unsubscribe();
    }

    if (this.Subcategoria && this.Subcategoria.closed == false){
      this.Subcategoria.unsubscribe();
    }
  }

  criar(){

    let obj = {
      "produto" : null,
      "categorias" : this.categorias
    }

    this.modalService.modalProdutos.emit(obj);
  }

  editar(produto: any){

    let obj = {
      "produto" : produto,
      "categorias" : this.categorias
    }

    this.modalService.modalProdutos.emit(obj);
  }
 
  adicionais(produto: any){

    let obj = {
      "produto" : produto,
      "produtos" : this.produtos
    }

    this.modalService.modalAdicionais.emit(obj);

  }

  excluir(produto: any){
    
    this.confirmationDialogService.confirm('Confirmar?', 'Realmente deseja excluir ?')
      .then((confirmed) =>{
          
        if(confirmed){
          this.cadastroService.excluir("produtos", produto.id);
            
          this.toastr.success('Produto excluído com sucesso!', 'Sucesso!');
          this.getProdutos();               
           
        }
        
      })
      .catch(() => {
        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
    
  }

}
