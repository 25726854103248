// Angular
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

// Dropdowns Component
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

// Components Routing
import { TableModule } from 'primeng/table';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AuthInterceptorService } from '../auth/auth-interceptor.service';
import { RelatoriosRoutingModule } from './relatorios-routing.module';
import DirectivesModule from '../directives/directives.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { PedidosComponent } from './pedidos/pedidos.component';
import { ObsComponent } from './pedidos/obs/obs.component';

@NgModule({ 
  imports: [
    CommonModule,
    FormsModule,
    RelatoriosRoutingModule,
    BsDropdownModule.forRoot(),
    TableModule,
    NgxSpinnerModule,
    ModalModule.forRoot(),
    AngularFireAuthModule,
    DirectivesModule,
    CollapseModule
  ], 
  declarations: [ 
    PedidosComponent,
    ObsComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    } 
  ]  
})
export class RelatoriosModule { }
