import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PedidosComponent } from './pedidos/pedidos.component';

const routes: Routes = [
  {
    path: '',
    data: {
      title: 'Relatorios'
    },
    children: [
     
      {
        path: 'pedidos',
        component: PedidosComponent,
        data: {
          title: 'Pedidos'
        }
      }
      
      
    ] 
  }
];
 
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RelatoriosRoutingModule {}
