import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CrudService } from "../../services/crudService";
import { ModalService } from '../../services/modal.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-categorias',
  templateUrl: './categorias.component.html',
  styleUrls: ['./categorias.component.scss']
})  
export class CategoriasComponent implements OnInit {
    
  SubCategoria: Subscription;
  categorias: any[];
  
  constructor(
      private spinner: NgxSpinnerService,
      private CrudService: CrudService,
      private modalService: ModalService,
      private cadastroService: CrudService,
      private toastr: ToastrService,
      private confirmationDialogService: ConfirmationDialogService
  ){
     
  }

  ngOnInit() {
    this.getCategorias();
  }

  async getCategorias(){
 
    this.categorias = [];

    if (this.SubCategoria && this.SubCategoria.closed == false){
      this.SubCategoria.unsubscribe();
    }

    if (!this.SubCategoria || this.SubCategoria.closed){

      this.spinner.show();
     
      this.SubCategoria = this.CrudService.listar("categorias", null, null).subscribe(
        (result: any) =>{

          this.categorias =  result;

          this.spinner.hide();
  
       },error=>{
            console.log(error);
            this.spinner.hide();
       });

    }
  
  }

  ngOnDestroy(){
    if (this.SubCategoria && this.SubCategoria.closed == false){
      this.SubCategoria.unsubscribe();
    }
  }

  criar(){
    this.modalService.modalCategorias.emit(null);
  }

  editar(categoria: any){
    this.modalService.modalCategorias.emit(categoria);
  }
 
  excluir(categoria: any){
    
    this.confirmationDialogService.confirm('Confirmar?', 'Realmente deseja excluir ?')
      .then((confirmed) =>{
          
        if(confirmed){
          this.cadastroService.excluir("categorias", categoria.id);
            
          this.toastr.success('Categoria excluída com sucesso!', 'Sucesso!');
          this.getCategorias();               
           
        }
        
      })
      .catch(() => {
        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
    
  }

}
