import { NgModule } from '@angular/core';
import { SimNaoDirective } from './sim-nao.directive';
import { Produtoirective } from './produto.directive';
import { ImgProdutoirective } from './img-produto.directive';
import { IdentificaoDirective } from './identificacao';

@NgModule({
    declarations: [
        SimNaoDirective,
        Produtoirective,
        ImgProdutoirective,
        IdentificaoDirective
    ], 

    exports: [
        SimNaoDirective,
        Produtoirective,
        ImgProdutoirective,
        IdentificaoDirective
    ]
})
export default class DirectivesModule{}