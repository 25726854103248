import { Directive, Input , ElementRef, Renderer2, OnInit} from '@angular/core';

@Directive({
  selector: '[appImgProduto]'
})
export class ImgProdutoirective implements OnInit{
    
  @Input() id: string;
  @Input() produtos: any[];

  constructor(private elRef: ElementRef, private render: Renderer2){

  }

  ngOnInit(){

    for(var i = 0; i < this.produtos.length; i++){

      if(this.produtos[i].id == this.id){

        var img = document.createElement('img');
        img.src = this.produtos[i].thumbnail; 
        
        this.render.setStyle(img, "width", "100%");
        this.render.setStyle(img, "heigth", "auto");
        this.render.appendChild(this.elRef.nativeElement, img);
      }
    }

  }
    

}

