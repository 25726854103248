import {Component, ViewChild, OnInit, TemplateRef, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { ModalService } from '../../../services/modal.service';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { CrudService } from '../../../services/crudService';

@Component({
  selector: 'app-obs',
  templateUrl: 'obs.component.html'
})  
export class ObsComponent implements OnInit{
      
  @ViewChild('modalAdicional', {static: false}) public modalAdicional: ModalDirective;

  sub: boolean = false;
  pedido: any;

  @Output() loadobs = new EventEmitter();
  titulo: string = "obs";
  movSubSalvar: Subscription;
  produtos: any[];
  obs: any[];
  produto: any;
  Subproduto: Subscription;

  constructor(
    private modalService: ModalService,
    private toastr: ToastrService,
    private crudService: CrudService) {
  }
  
  ngOnInit() {

    if(this.sub == false){

      this.sub = true;

      this.modalService.modalObs.subscribe(
        (obj: any) => {
         
          this.pedido = obj;

          this.limpaModal();
          this.modalAdicional.show();

        }, error => {
          console.log(error.message);
        } 
      );
    } 
 
  }


  hideModal(){
    this.modalAdicional.hide();
    this.loadobs.emit();
  }
 
  limpaModal(){
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  salvar() { 

    if(this.pedido.obs != ""){
      this.pedido.statusUsuario = "Cancelado"; 
      this.crudService.atualizar("pedidos", this.pedido);
      this.toastr.success('Pedido cancelado!', 'Sucesso!');
      this.hideModal();
    }else{
      this.toastr.warning('Informe o motivo do cancelamento!', 'Atenção');
    }
    
    
   
  }

}
