import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CrudService } from '../../services/crudService';
import { NgxSpinnerService } from 'ngx-spinner';
import { Pedido } from '../../model/pedido';
import { IfStmt } from '@angular/compiler';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from '../../services/modal.service';
 
@Component({
  selector: 'app-pedidos',
  templateUrl: './pedidos.component.html',
  styleUrls: ['./pedidos.component.scss']
})  
export class PedidosComponent implements OnInit {
      
  pedidos: Pedido[]; 
  colaboradores: any[];
  usuarios: any[];

  periodoDe = new Date(new Date().getFullYear(),  new Date().getMonth(), 1, 0, 0, 0, 0);
  periodoAte = new Date(new Date().getFullYear(),  new Date().getMonth()+1, 1, 0, 0, 0, 0);
  isCollapsed: boolean = false;
  movSubpedido: Subscription;
  _credito: string = "Todos";
  _debito: string = "Todos";
  _dinheiro: string = "Todos";
  _carteira: string = "Todos";
  _entrega: string = "Todos";
  _retirar: string = "Todos";
  _status: string = "Todos";

  total: number = 0;
  statusUsuario = [
    {
      "nome" : "Pendente"
    },
    {
      "nome" : "Em preparação"
    },
    {
      "nome" : "Saiu para entrega"
    },
    {
      "nome" : "Pronto para retirada"
    },
    {
      "nome" : "Cancelado"
    },
    {
      "nome" : "Finalizado"
    }
  ]
  movSubUsuarios: Subscription;
  constructor(
      private crudService: CrudService,
      private spinner: NgxSpinnerService,
      private toastr: ToastrService,
      private modalService: ModalService
  ){
    
  }

  ngOnInit() {
    this.spinner.show();

    this.getUsuarios();
 
  }

  mostrarDetalhes(){
    for(let a of this.pedidos){
      a.collapsed = false;
    }

    this.isCollapsed = !this.isCollapsed;
  }

  ocultarDetalhes(){
    for(let a of this.pedidos){
      a.collapsed = true;
    }

    this.isCollapsed = !this.isCollapsed;
  }

  collapsed(event: any): void {
    // console.log(event);
  }

  expanded(event: any): void {
    // console.log(event);
  }

  async getPedidos(){
 
    this.pedidos = [];

    if (this.movSubpedido && this.movSubpedido.closed == false){
      this.movSubpedido.unsubscribe();
    }

    if (!this.movSubpedido || this.movSubpedido.closed){

     let filtro = [];
     
      if(this._credito && this._credito == "S"){
        let _col  ={
          "campo" : "formaPagCredito",
          "operacao" : "==",
          "value" : true
        }
        filtro.push(_col);
      }
      
      if(this._credito && this._credito == "N"){
        let _col  ={
          "campo" : "formaPagCredito",
          "operacao" : "==",
          "value" : false
        }
        filtro.push(_col);
      }
      
          
      if(this._debito && this._debito == "S"){
        let _col  ={
          "campo" : "formaPagDebito",
          "operacao" : "==",
          "value" : true
        }
        filtro.push(_col);
      }
      if(this._debito && this._debito == "N"){
        let _col  ={
          "campo" : "formaPagDebito",
          "operacao" : "==",
          "value" : false
        }
        filtro.push(_col);
      }

          
      if(this._dinheiro && this._dinheiro == "S"){
        let _col  ={
          "campo" : "formaPagDinheiro",
          "operacao" : "==",
          "value" : true
        }
        filtro.push(_col);
      }
      if(this._dinheiro && this._dinheiro == "N"){
        let _col  ={
          "campo" : "formaPagDinheiro",
          "operacao" : "==",
          "value" : false
        }
        filtro.push(_col);
      }

          
      if(this._carteira && this._carteira == "S"){
        let _col  ={
          "campo" : "formaPagCarteira",
          "operacao" : "==",
          "value" : true
        }
        filtro.push(_col); 
      }
      if(this._carteira && this._carteira == "N"){
        let _col  ={
          "campo" : "formaPagCarteira",
          "operacao" : "==",
          "value" : false
        }
        filtro.push(_col);
      }

      if(this._entrega && this._entrega == "S"){
        let _col  ={
          "campo" : "entregar",
          "operacao" : "==",
          "value" : true
        }
        filtro.push(_col); 
      }
      if(this._entrega && this._entrega == "N"){
        let _col  ={
          "campo" : "entregar",
          "operacao" : "==",
          "value" : false
        }
        filtro.push(_col);
      }

      if(this._retirar && this._retirar == "S"){
        let _col  ={
          "campo" : "retirar",
          "operacao" : "==",
          "value" : true
        }
        filtro.push(_col); 
      }
      if(this._retirar && this._retirar == "N"){
        let _col  ={
          "campo" : "retirar",
          "operacao" : "==",
          "value" : false
        }
        filtro.push(_col);
      }

      if(this._status && this._status != "Todos"){
        let _col  ={
          "campo" : "statusUsuario",
          "operacao" : "==", 
          "value" : this._status
        }
        filtro.push(_col); 
      }


    this.movSubpedido = this.crudService.listarByFiltro("pedidos", filtro).subscribe(
        (result: any) =>{

          this.pedidos = [];

          for(var a = 0; a < result.length; a++){
            this.pedidos.push(result[a]);
          } 

          this.pedidos = this.pedidos.filter((este, i) => this.pedidos.indexOf(este) === i);
          this.setTotal();
          this.spinner.hide();
          
         
       },error=>{
            console.log(error);
       });

    }
    

  }

  getUsuarios(){

    this.movSubUsuarios = this.crudService.listarByFiltro("usuarios", null).subscribe(
      (result: any) =>{

        this.usuarios = [];

        this.usuarios = result;

        this.getPedidos();
                
       
     },error=>{
          console.log(error);
     });

  }

  setTotal(){
    this.total = 0;
    for(let p of this.pedidos){
        this.total = Number((this.total + p.total).toFixed(2));
    }
  }

  onChangeDateDe(De: Date){
    let _De = new Date(De);
    this.periodoDe = new Date(_De.getFullYear(),  _De.getMonth(), _De.getDate()+1, 0, 0, 0, 0);
    this.spinner.show();
    this.getPedidos();
  }

  onChangeDateAte(Ate: Date){
    let _Ate = new Date(Ate);
    this.periodoAte = new Date(_Ate.getFullYear(),  _Ate.getMonth(), _Ate.getDate()+1, 0, 0, 0, 0);
    this.spinner.show();
    this.getPedidos();
  }

  ngOnDestroy(){
    if (this.movSubpedido && this.movSubpedido.closed == false){
      this.movSubpedido.unsubscribe();
    }
  }

  onChange(value: any, pedido: any) { 

    if(value == "Cancelado"){
      this.modalService.modalObs.emit(pedido);
    }else{
      pedido.statusUsuario = value;
      this.crudService.atualizar("pedidos", pedido);
      this.toastr.success('Status do cliente atualizado!', 'Sucesso!');
    }
   
  }

}
