import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, tap } from 'rxjs/operators';
import { throwError, BehaviorSubject } from 'rxjs';

import { User } from './user.model';
import { AngularFireAuth } from '@angular/fire/auth';
import { FirebaseServiceProvider } from '../providers/firebase-service';

export interface AuthResponseData {
  kind: string;
  idToken: string;
  email: string;
  refreshToken: string;
  expiresIn: string;
  localId: string;
  registered?: boolean;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  user = new BehaviorSubject<User>(null);
  private tokenExpirationTimer: any;

  public afAuth: AngularFireAuth = this.fbService.auth;
  
  constructor(
    private router: Router,
    private fbService: FirebaseServiceProvider 
  ) {}

  login(email: string, password: string) : any{
    return this.afAuth.auth.signInWithEmailAndPassword(email, password);
    
  }

  autoLogin() {

    const userData: {
      email: string;
      id: string;
      _token: string;
      _tokenExpirationDate: string;
    } = JSON.parse(localStorage.getItem('userNextChefPortal'));

    
    if (!userData) {
      return false;
    }

    const loadedUser = new User(
      userData.email,
      userData.id,
      userData._token,
      new Date(userData._tokenExpirationDate)
    );

    if (loadedUser.token) {
      this.user.next(loadedUser);
      const expirationDuration =
        new Date(userData._tokenExpirationDate).getTime() -
        new Date().getTime();

      this.autoLogout(expirationDuration);
      return true;
    }
  }

  logout() {
    this.user.next(null);
    this.router.navigate(['/login']);
    localStorage.removeItem('userNextChefPortal');
    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
    }
    this.tokenExpirationTimer = null;
  }

  autoLogout(expirationDuration: number) {
    this.tokenExpirationTimer = setTimeout(() => {
      this.logout();
    }, expirationDuration);
  }
  
public handleAuthentication(
    email: string,
    userId: string,
    token: string,
    expiresIn: number
  ) {
    const expirationDate = new Date(new Date().getTime() + expiresIn * 1000);
    const user = new User(email, userId, token, expirationDate);
    this.user.next(user);
    this.autoLogout(expiresIn * 1000);
    localStorage.setItem('userNextChefPortal', JSON.stringify(user));
  }

  public handleErrorByRest(errorRes: HttpErrorResponse) {

    let errorMessage = 'Erro desconhecido!';
    if (!errorRes.error || !errorRes.error.error) {
      return throwError(errorMessage);
    }
    switch (errorRes.error.error.message) {
      case 'EMAIL_EXISTS':
        errorMessage = 'E-mail já cadastrado!';
        break;
      case 'EMAIL_NOT_FOUND':
        errorMessage = 'E-mail não existe.';
        break;
      case 'INVALID_PASSWORD':
        errorMessage = 'Senha incorreta.';
        break;
    }
    return throwError(errorMessage);
  }

  public handleError(errorRes: String) {
    let errorMessage = 'Erro desconhecido!';
  
    switch (errorRes) {
      case 'auth/user-not-found':
        errorMessage = 'E-mail não existe.';
        break;
      case 'auth/wrong-password':
        errorMessage = 'Senha incorreta.';
        break;
    }
    return errorMessage;
  }
}
