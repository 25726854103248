// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  
  firebaseConfig: {
    apiKey: "AIzaSyBVKPeUUmjAXW6jsW7a84tvp-7_kkkaxs4",
    authDomain: "nbchef-26f53.firebaseapp.com",
    databaseURL: "https://nbchef-26f53.firebaseio.com",
    projectId: "nbchef-26f53",
    storageBucket: "nbchef-26f53.appspot.com",
    messagingSenderId: "175127597299",
    appId: "1:175127597299:web:123c06fc11450a2f"
  }

};

