import {Component, ViewChild, OnInit, TemplateRef, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { ModalService } from '../../../services/modal.service';
import * as $ from 'jquery';
import { Subscription, Observable } from 'rxjs';
import { Produto } from '../../../model/produto';
import { NgForm } from '@angular/forms';
import { CrudService } from "../../../services/crudService";
import { ToastrService } from 'ngx-toastr';
import { AngularFireStorage, AngularFireUploadTask } from 'angularfire2/storage';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-detalhes-produtos',
  templateUrl: 'produtos-detalhes.component.html'
})  
export class ProdutosDetalhesComponent implements OnInit{
      
  @ViewChild('modalproduto', {static: false}) public modalproduto: ModalDirective;

  sub: boolean = false;
  _produto: Produto = new Produto();
  @Output() loadprodutos = new EventEmitter();
  titulo: string = "Novo Produto";
  movSubSalvar: Subscription;
  movSubAlterar: Subscription;
  categorias: any[];

   // Main task 
   task: AngularFireUploadTask;

   // Progress monitoring
   percentage: Observable<number>;
 
   snapshot: Observable<any>;
 
   // Download URL
   downloadURL: Observable<string>;
 
   // State for dropzone CSS toggling
   isHovering: boolean;
 
   files: any[] = [];

   btnStart: boolean = true;
   lblBtnEnviar: string = "Salvar";
 

  constructor(
    private storage: AngularFireStorage,
    private modalService: ModalService,
    private toastr: ToastrService,
    private cadastroService: CrudService) {
  }
  
  ngOnInit() {

    if(this.sub == false){

      this.sub = true;

      this.modalService.modalProdutos.subscribe(
        (obj: any) => {
         
          if(obj.produto == null){
            this.titulo = "Novo Produto"
            this._produto = new Produto();
          }else{
            this.titulo = "Editar Produto"
            this._produto = obj.produto;
          }

          this.categorias = obj.categorias;
          this.limpaModal();

          this.files = [];
          this.percentage = null;
          this.modalproduto.show();

        }, error => {
          console.log(error.message);
        } 
      );
    } 
 
  }

  hideModal(){
    this.modalproduto.hide();
    this.loadprodutos.emit();
  }
 
  limpaModal(){
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
    $('#file').val("");
  }

  onSubmit(form: NgForm) {

    if (!form.valid) {
      return;
    }
   this.startUpload();
      
    form.reset();
  }

  salvar(objproduto){
 
    if (this.movSubSalvar && this.movSubSalvar.closed == false){
      this.movSubSalvar.unsubscribe();
    }

    if (!this.movSubSalvar || this.movSubSalvar.closed){
     
      this.movSubSalvar = this.cadastroService.findByGeneric("produtos", "codigo", objproduto.codigo).subscribe(
        (result: any) =>{

          if(result.length > 0){
            this.toastr.error('Este código já esta sendo utilizado por outro produto!', 'Atenção!');
            this.hideModal();
          }else{
            this.cadastroService.salvar("produtos", objproduto);

            this.toastr.success('Produto cadastrado com sucesso!', 'Sucesso!');
        
            this.hideModal();

            this.btnStart = true;
            this.lblBtnEnviar = "Salvar"

            this.movSubSalvar.unsubscribe();
          }
  
       },error=>{
            console.log(error);
            this.hideModal();
       });

    }
   
  } 

  atualizar(objproduto){

    if (this.movSubAlterar && this.movSubAlterar.closed == false){
      this.movSubSalvar.unsubscribe();
    }

    if (!this.movSubAlterar || this.movSubAlterar.closed){
     
      this.movSubAlterar = this.cadastroService.findByGeneric("produtos", "codigo", objproduto.codigo).subscribe(
        (result: any) =>{

          if(result.length > 0){
            if(result[0].codigo == objproduto.codigo){
              this.cadastroService.atualizar("produtos", objproduto);

              this.toastr.success('Produto atualizado com sucesso!', 'Sucesso!');
         
              this.hideModal();
            
              this.btnStart = true;
              this.lblBtnEnviar = "Enviar"

              this.movSubAlterar.unsubscribe();
            }
          }
  
       },error=>{
            console.log(error);
            this.hideModal();
       });

    }

  }

  
toggleHover(event: boolean) {
  this.isHovering = event;
}

addFile(event: FileList) {
  this.files = [];
  const file = event.item(0)
  this.files.push(file);
}

startUpload() {

  if(this.files && this.files.length > 0){
      this.btnStart = false;
      this.lblBtnEnviar = "Aguarde..."

      for(let f of this.files){

        // The File object
        const file = f;

        // The storage path
        const path = `assets/${file.name}`;

        // The main task
        this.task = this.storage.upload(path, file);

        // Progress monitoring
        this.percentage = this.task.percentageChanges();
        this.snapshot   = this.task.snapshotChanges();

        // The file's download URL
        const task = this.storage.upload(path, file);
        const ref = this.storage.ref(path);
    
        let objproduto = JSON.parse(JSON.stringify(this._produto));
        task.snapshotChanges().pipe(
          
            finalize(() => {
                ref.getDownloadURL().subscribe(url => {
                          
                objproduto.thumbnail = url;

                if(objproduto.id){
                  this.atualizar(objproduto);
                }else{
                  this.salvar(objproduto);
                }

                setTimeout(()=>{    
                  this.percentage = null;
                }, 1000);
                
  
              });
      
            })
          
        ).subscribe();
    
        }
      }else{

        let objproduto = JSON.parse(JSON.stringify(this._produto));
        
        if(objproduto.id){
          this.atualizar(objproduto);
        }else{
          this.toastr.warning('Selecione algum arquivo!', 'Atenção!');
        }

       
      }

  }

  // Determines if the upload task is active
  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes
  }

}
