
export class Adicional {

    public id: string;
    public produtoPai: string;
    public produtoAdicional: string;
    
    constructor(){
        
    }
}
