
export class Categoria {

    public id: string;
    public codigo: string;
    public nome: string;
    public icon: string;

    constructor(){
        
    }
}
