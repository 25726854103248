
export class Produto {

    public id: string;
    public codigo: string;
    public categoria: string;
    public descricao: string;
    public destaque: boolean;
    public nome: string;
    public thumbnail: string;
    public valor: number;
    public vender: boolean;
    
    constructor(){
        
    }
}
