import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';

import { environment } from "../environments/environment";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { ToastrModule } from 'ngx-toastr';
import { ComponentsModule } from './components/components.module';
import { HttpClientModule } from '@angular/common/http';
import { AuthComponent } from './auth/auth.component';
import { FormsModule } from '@angular/forms';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth, AngularFireAuthModule } from '@angular/fire/auth';
import { FirebaseServiceProvider, firebaseInit } from './providers/firebase-service';
import { StorageServiceModule } from 'ngx-webstorage-service';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { RelatoriosModule } from './relatorios/relatorios.module';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule ,
    ToastrModule.forRoot(), // ToastrModule added  
    ComponentsModule,
    HttpClientModule,
    FormsModule,
    StorageServiceModule,
    NgbModule,
    AngularFireStorageModule,
    RelatoriosModule
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    AuthComponent,
    LoadingSpinnerComponent
  ],
  providers: [
    FirebaseServiceProvider,
    { provide: APP_INITIALIZER, useFactory: firebaseInit, deps: [FirebaseServiceProvider], multi: true },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    AngularFireDatabase,
    AngularFireAuth,
    AngularFireAuthModule
  ],
  bootstrap: [ AppComponent ],
  entryComponents: [ConfirmationDialogComponent]
})
export class AppModule { }
