import { EventEmitter, Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class ModalService {

  modalCategorias = new EventEmitter<any>();
  modalProdutos = new EventEmitter<any>();
  modalAdicionais = new EventEmitter<any>();
  modalObs = new EventEmitter<any>();

  constructor() {}

  
}
